// extracted by mini-css-extract-plugin
export var cardContainer = "style-module--card-container--8bdf4";
export var cardCover = "style-module--card-cover--d0e98";
export var cardExtra = "style-module--card-extra--05790";
export var cardGenre = "style-module--card-genre--445fb";
export var cardInfo = "style-module--card-info--9a03e";
export var cardName = "style-module--card-name--84d45";
export var cardNoCover = "style-module--card-no-cover--a51bf";
export var cardRole = "style-module--card-role--d317b";
export var cardStatus = "style-module--card-status--910f4";
export var cardWrapper = "style-module--card-wrapper--28824";